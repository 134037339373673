<template>
		<div>
			<home-app-bar :drawer="drawer" />

			<home-view />

			<home-footer />
		</div>
</template>

<script>

  export default {
    name: 'adminLayout',

    components: {
      HomeAppBar: () => import('@/layouts/global-components/app-bar'),
      HomeFooter: () => import('@/layouts/global-components/footer'),
      HomeSettings: () => import('@/layouts/admin-portal/settings'),
      HomeSystemBar: () => import('@/layouts/admin-portal/system-bar'),
      HomeView: () => import('@/layouts/admin-portal/view'),
		},
		data() {
			return {
				// Drawer
				drawer: true,
			}
		},
		watch: {
			
		}

  }
</script>

    